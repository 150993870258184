<template>
	<div class="paperCompound">
		<!-- 材料导航 -->
		<material-banner :menuIndex="2"></material-banner>
		
		<!-- 产品特点 -->
		<div class="region-com region-trait k_flex_column_jcc_aic">
			<div class="region-desc k_flex_wrap_jcsb">
				<div class="region-desc-single k_flex_column_aic" v-for="(item,index) in traitList" :key="index">
					<img :src="fourprooinUrl+item.img" class="region-desc-img"/>
					<div class="region-desc-cont k_flex_column_aifs">
						<p>{{item.title}}</p>
						<p>{{item.content}}</p>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 使用行业 -->
		<div class="region-com region-suitable k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.Applications') }}</span>
				<span class="region-title-deputy">{{ $t('m.MedicalLogisticsCatering') }}</span>
			</div>
			<div class="region-desc k_flex_jcc_aic">
				<img :src="fourprooinUrl+item.img" v-for="(item,index) in suitableList" :key="index"/>
			</div>
		</div>
		
		<!-- 产品规格 -->
		<div class="region-com region-size k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.TechnicalSpecification') }}</span>
				<span class="region-title-deputy">{{ $t('m.Ultraresistant') }}</span>
			</div>
			<div class="region-cont">
				<el-table
					:data="tableData"
					:span-method="objectSpanMethod"
					border
					style="width: 100%;font-size: 16px;">
					<el-table-column
						prop="name"
						label="产品名称"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Product')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="type"
						label="产品型号"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Model')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="standards"
						label="产品规格"
						align="center"
						width="160">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Specification')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="metre"
						label="米数"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Length')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="introduce"
						label="介绍"
						align="center"
						width="599">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Introduction')}}</div>
						</template>
						<template slot-scope="scope">
							<div class="region-cont-introduce">
								<div class="region-cont-introduce-single" v-for="(item,index) in scope.row.introduce">
									<span>{{item.title}}</span>
									<span>{{item.content}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<img :src="fourprooinUrl+$t('m.papersizepng')" class="region-desc" />
		</div>
	</div>
</template>

<script>
import materialBanner from '@c/materialBanner/materialBanner'
export default{
	components:{
		materialBanner,
	},
	data() {
		return {
			// traitList:[
			// 	{
			// 		title:'酒精浸泡字体5分钟字迹依然清晰',
			// 		content:'75°消毒酒精直接喷洒3-5毫升在纸上，5分钟后在打印面反复摩擦10次以上字体依然清晰，这才是马斯夫为您提供的合格四防产品。',
			// 		img:'trait_01.png'
			// 	},
			// 	{
			// 		title:'常温水浸泡24小时字迹依然清晰',
			// 		content:'常温水浸泡24小时，色密度仍在0.9，100度高温沸水冲烫1分钟，字迹还是杠杠的。',
			// 		img:'trait_02.png'
			// 	},
			// 	{
			// 		title:'油滴浸泡48小时字体不褪色，字迹依然清晰',
			// 		content:'油浸泡48小时后色密度仍然高达1.0以上, 可用于后厨备餐、速食包装火锅底料标签等需要长时间耐油污的环境，为餐饮行业解决标签怕油污的问题。 ',
			// 		img:'trait_03.png'
			// 	},
			// 	{
			// 		title:'耐刮擦、耐塑性强',
			// 		content:'马斯夫四防耐塑性强，使用缠绕膜或透明胶带粘贴缠绕，可保证6个月后打印面字迹可辨识，色密度在0.8以上 ；耐刮擦性能更是经过sgs专业国际机构认证 ，  货物运输过程中再不怕标签刮花脱落。',
			// 		img:'trait_04.png'
			// 	}
			// ],
			// suitableList:[
			// 	{
			// 		img:'suitable_01.png'
			// 	},
			// 	{
			// 		img:'suitable_02.png'
			// 	},
			// 	{
			// 		img:'suitable_03.png'
			// 	}
			// ],
			// tableData: [
			// 	{
			// 		name: '四防热敏纸',
			// 		type: 'SJP4680',
			// 		standards: '1090mm',
			// 		metre: '8000m',
			// 		introduce: [
			// 			{
			// 				title:'防水：',
			// 				content:'常温水浸泡24小时，色密度依旧0.9；'
			// 			},
			// 			{
			// 				title:'防酒精：',
			// 				content:'75℃的消毒酒精喷洒5分钟后摩擦10次依旧清晰；'
			// 			},
			// 			{
			// 				title:'防油：',
			// 				content:'油浸泡48小时，色密度依旧0.8；'
			// 			},
			// 			{
			// 				title:'防刮擦：',
			// 				content:'SGS国际检测机构专业认证，四级以上色牢度；'
			// 			},
			// 			{
			// 				title:'耐塑：',
			// 				content:'透明膜或者胶带粘贴72小时仍然字迹清晰；'
			// 			}
			// 		]
			// 	}, 
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1090mm',
			// 		metre: '6000m',
			// 		introduce: []
			// 	},
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1550mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	},
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1650mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	}
			// ]
		}
	},
	computed: {
		fourprooinUrl() {
		  return 'https://oss-www.kymasf.com/img/material/fourproofing/'
		},
		traitList(){
		return	[
				{
					title:this.$t('m.Textsremain'),
					content:this.$t('m.Spraythepaper'),
					img:this.$t('m.papertrait_01')
				},
				{
					title:this.$t('m.Thehandwriting'),
					content:this.$t('m.Soakinroom'),
					img:this.$t('m.papertrait_02')
				},
				{
					title:this.$t('m.Textdensityimmersion'),
					content:this.$t('m.Thepapercan'),
					img:this.$t('m.papertrait_03')
				},
				{
					title:this.$t('m.plasticizerandscratch'),
					content:this.$t('m.Afterwrapping'),
					img:this.$t('m.papertrait_04')
				}
			]
		},
		tableData(){
			return [
				{
					name: this.$t('m.Fourproofthermalsensitivepaper'),
					type: 'SJP4680',
					standards: '1090mm',
					metre: '8000m',
					introduce: [
						{
							title:this.$t('m.Waterprooftitle'),
							content:this.$t('m.Waterproof')
						},
						{
							title:this.$t('m.Alcoholresistanttitle'),
							content:this.$t('m.Alcoholresistant')
						},
						{
							title:this.$t('m.Oilresistanttitle'),
							content:this.$t('m.Oilresistant')
						},
						{
							title:this.$t('m.Antiscratchtitle'),
							content:this.$t('m.Antiscratch')
						},
						{
							title:this.$t('m.Plasticizerresistanttitle'),
							content:this.$t('m.Plasticizerresistant')
						}
					]
				}, 
				{
					name: '',
					type: '',
					standards: '1090mm',
					metre: '6000m',
					introduce: []
				},
				{
					name: '',
					type: '',
					standards: '1550mm',
					metre: '12000m',
					introduce: []
				},
				{
					name: '',
					type: '',
					standards: '1650mm',
					metre: '12000m',
					introduce: []
				}
			]
		},
		suitableList(){
			return[
				{
					img:this.$t('m.papersuitableListimg1')
				},
				{
					img:this.$t('m.papersuitableListimg2')
				},
				{
					img:this.$t('m.papersuitableListimg3')
				}
			]
		}
	},
	mounted() {
		
	},
	methods: {
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
				if (rowIndex % 4 === 0) {
					return {
						rowspan: 4,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.paperCompound{
	width: 100%;
	height: 100%;
	background: #fff;
	// 区域公共样式
	.region-com{
		width: 100%;
		.region-title{
			.region-title-main{
				margin-top: 48px;
				height: 48px;
				font-size: 42px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2C3F64;
				line-height: 48px;
				border-bottom: 2px dashed #535353;
				padding-bottom: 15px;
				margin-bottom: 15px
			}
			.region-title-deputy{
				width: 981px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2C3F64;
				text-align: center;
			}
		}
	}
	
	//产品特点
	.region-trait{
		background: #F7F7F7;
		.region-title{
			
		}
		.region-desc{
			width: 1200px;
			margin-top: 74px;
			margin-bottom: 59px;
			.region-desc-single{
				position: relative;
				&:nth-child(n+3){
					margin-top: 42px;
				}
				.region-desc-img{
					
				}
				.region-desc-cont{
					position: absolute;
					width: 534px;
					height: 160px;
					padding: 17px;
					box-sizing: border-box;
					bottom: 0;
					left: 0;
					p{
						&:nth-child(1){
							font-size: 20px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #2D6CFF;
						}
						&:nth-child(2){
							margin-top: 12px;
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #1C1C1C;
							overflow-y:auto ;
						}
					}
				}
			}
		}
	}
	
	//使用行业
	.region-suitable{
		background: #575962;
		.region-title{
			.region-title-main{
				color: #fff;
				border: none;
				margin-bottom: 0;
			}
			.region-title-deputy{
				color: #fff;
			}
		}
		.region-desc{
			width: 1200px;
			margin-top: 40px;
			margin-bottom: 68px;
			img{
				&:nth-child(2){
					margin: 0 163px;
				}
			}
		}
	}
	
	//产品规格
	.region-size{
		.region-title{
			.region-title-main{
				color: #2E3136;
				border: none;
				margin-bottom: 0;
			}
			.region-title-deputy{
				font-family: HiraginoSansGB;
				font-weight: normal;
				color: #2D6CFF;
			}
		}
		.region-cont{
			margin-top: 30px;
			width: 1200px;
			background: #2D6CFF;
			padding: 10px;
			border-radius: 8px;
			box-sizing: border-box;
			font-size: 16px;
			.region-cont-introduce{
				.region-cont-introduce-single{
					font-size: 16px;
					text-align: left;
				}
			}
		}
		.region-desc{
			width: 1200px;
			margin-top: 20px;
			margin-bottom: 62px;
		}
	}
}
</style>